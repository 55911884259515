<script>
	import Modal from './modal.svelte'

	export let members

	let activePerson = null

	const closePopup = () => (activePerson = null)
</script>

<style>
.tp-row {
	overflow: auto;
	font-size: 17px;
}
h2 {
	font-size: 45px;
	font-weight: 400;
	letter-spacing: 0.01em;
	line-height: 1.1;
	color: #3a3a3a;
}
p {
	line-height: 1.9;
}
.tp-title {
	font-weight: bold;
	background: #662b8f;
	color: #fff;
	display: table;
	padding: 4px 8px;
	line-height: 1.3;
	font-size: 13.5px;
	margin: 17px 0 19px;
	border-radius: 3px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}
.tp-img {
	float: right;
	width: 400px;
	flex-shrink: 0;
	margin: 5px 0 10px 5%;
	max-width: 40%;
	border-radius: 2px;
}
.tp-img > div {
	background-color: #eee;
	padding-bottom: 130%;
	border-radius: 3px;
	background-size: cover;
	background-position: center;
}
.tp-content {
	width: 100%;
	color: #484848;
}
@media (max-width: 600px) {
	h2 {
		font-size: 37px;
	}
	.tp-row {
		display: flex;
		flex-direction: column;
		font-size: 16px;
	}

	.tp-img {
		float: none;
		max-width: 100%;
		margin: 9px 0;
		order: 2;
	}
}
</style>

{#each members as member}
	<div class="team-mem" on:click={() => (activePerson = member)}>
		<div class="tm-img" style="background-image:url({member.img.sm})" />
		<h3>{member.name}</h3>
		<h4>{member.position}</h4>
	</div>
{/each}

{#if activePerson}
	<Modal width={1120} on:close={closePopup}>
		<div class="tp-row">
			<div class="tp-img">
				<div style={`background-image:url(${activePerson.img.lg})`} />
			</div>
			<div class="tp-content">
				<h2>{activePerson.name}</h2>
				<p class="tp-title">{activePerson.position}</p>
				<div>
					{@html activePerson.content}
				</div>
			</div>
		</div>
	</Modal>
{/if}
