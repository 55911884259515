import * as basicScroll from 'basicscroll'
import BigPicture from 'bigpicture'

const { $ } = window

export default {
	finalize() {
		let $hl_images = $('#hl_images')
		let innerWidth = document.documentElement.clientWidth

		let scrollAnimations = [
			basicScroll.create({
				elem: document.getElementById('glitter'),
				from: 'top-bottom',
				to: 'bottom-top',
				direct: true,
				props: {
					'--ty':
						innerWidth > 1400
							? {
									from: '70px',
									to: '-80px',
							  }
							: {
									from: '40px',
									to: '-50px',
							  },
				},
			}),
			basicScroll.create({
				elem: document.getElementById('bottles'),
				from: 'top-bottom',
				to: 'bottom-top',
				direct: true,
				props: {
					'--ty':
						innerWidth > 1400
							? {
									from: '-55px',
									to: '99px',
							  }
							: {
									from: '-30px',
									to: '50px',
							  },
				},
			}),
		]

		scrollAnimations.forEach(instance => instance.start())

		// image enlarge
		if ($hl_images.length) {
			$hl_images.find('div').on('click', function() {
				BigPicture({
					el: this,
					gallery: '#hl_images',
					loop: true,
				})
			})
		}
	},
}
