<script>
	import BigPicture from 'bigpicture'
	import lozad from 'lozad'
	import { onMount } from 'svelte'

	export let images = []

	let isDragging = false
	let dragAmount = 0
	let translate = 0
	let initialTranslate = 0
	let mouseDownPos = 0
	let clickedInside = false

	let containerWidth = 0
	let dragImagesWidth = 0

	function mousedown(e) {
		const { clientX } = e.targetTouches ? e.targetTouches[0] : e
		clickedInside = true
		mouseDownPos = clientX
	}
	function mouseup() {
		isDragging = clickedInside = false
		initialTranslate = translate
	}
	function mousemove(e) {
		if (clickedInside) {
			isDragging = true
			const { clientX } = e.targetTouches ? e.targetTouches[0] : e
			dragAmount = (mouseDownPos - clientX) * -1
			translate = Math.min(
				0,
				Math.max(initialTranslate + dragAmount, edgeGap - 8)
			)
		}
	}

	onMount(() => {
		const observer = lozad('#drag_track > div')
		observer.observe()
	})

	$: edgeGap = containerWidth - dragImagesWidth
</script>

<svelte:window
	on:mouseup={mouseup}
	on:touchend={mouseup}
	on:mousemove={mousemove}
	on:touchmove={mousemove}
	on:resize={() => (translate = initialTranslate = 0)} />

<section
	class="drag-images-container"
	bind:clientWidth={containerWidth}
	data-slideout-ignore>
	<div
		class="drag-images"
		id="drag_track"
		class:draggable={edgeGap < 0}
		style="transform: translatex({translate}px)"
		bind:clientWidth={dragImagesWidth}
		on:mousedown={mousedown}
		on:touchstart={mousedown}>
		{#each images as img}
			<div
				data-background-image={img.sm}
				data-bp={img.lg}
				on:mouseup={e => !isDragging && BigPicture({
						el: e.target,
						gallery: '.drag-images',
					})} />
		{/each}
	</div>
</section>
