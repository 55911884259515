<script>
	import FocusLocker from 'focus-locker'
	import { fade, fly } from 'svelte/transition'
	import { createEventDispatcher } from 'svelte'

	const dispatch = createEventDispatcher()

	export let width = 999

	// bindings
	let modal

	const closeModal = () => dispatch('close')

	const wrapClick = e => e.target === e.currentTarget && closeModal()

	const onShow = () => {
		// show scrollbars
		dispatch('mount')
		// document.documentElement.style.overflowY = 'auto'
		// document.body.style.overflowY = 'auto'
		// restore trapped focus
		FocusLocker.release()
	}

	const onClose = () => {
		// hide scrollbars
		// document.documentElement.style.overflowY = 'hidden'
		// document.body.style.overflowY = 'scroll'
		// focus trap to modal
		FocusLocker.request(modal)
	}
</script>

<style>
.svelte-modal-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.35);
	z-index: 9999;
	display: flex;
	backface-visibility: hidden;
	overflow: auto;
}
.svelte-modal-wrap .main {
	padding: 40px 4%;
	margin: auto;
}
.svelte-modal {
	position: relative;
	width: 100%;
	transition: transform 0.35s;
	border-radius: 3px;
	padding: 5px;
	/* background: linear-gradient(25deg, #e351c3, #662b8f); */
	background: #ccc;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
}
.close-modal {
	background-color: #662b8f;
	color: #fff;
	position: absolute;
	right: -16px;
	top: -16px;
	border-radius: 100%;
	width: 42px;
	height: 42px;
	border: 0;
	padding-top: 3px;
	padding-right: 6px;
	transition: background-color 0.2s;
	text-align: left;
	border: 2px solid #fff;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
	z-index: 1;
}
.close-modal:hover {
	background-color: #7a37aa;
}
.close-modal:active {
	background-color: #5c2783;
}
.close-modal svg {
	position: absolute;
	width: 17px;
	height: 17px;
	fill: #fff;
	top: 11px;
	left: 11px;
}
.modal-inner {
	padding: 33px 30px;
	background: #fff;
	/* background: linear-gradient(
		165deg,
		rgba(255, 255, 255, 0.95),
		rgba(255, 255, 255, 0.9)
	); */
}
@media (max-width: 600px) {
	.svelte-modal {
		border-width: 2px;
	}
	.close-modal {
		right: -11px;
		top: -11px;
		width: 34px;
		height: 34px;
		border-width: 1px;
	}
	.close-modal svg {
		width: 15px;
		height: 15px;
		top: 9px;
		left: 9px;
	}
	.modal-inner {
		padding: 35px 28px 15px;
	}
}
</style>

<svelte:window on:keyup={e => e.keyCode === 27 && closeModal()} />

<div
	class="svelte-modal-wrap"
	data-slideout-ignore
	on:click={wrapClick}
	transition:fade={{ duration: 300 }}
	on:introstart={onClose}
	on:outroend={onShow}>
	<div class="main" on:click={wrapClick}>
		<div class="inner-wrap" style={`max-width:${width}px`}>
			<div
				class="svelte-modal"
				in:fly={{ y: 10 }}
				out:fly={{ y: -10 }}
				bind:this={modal}>
				<button class="close-modal" on:click={closeModal}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971">
						<path
							d="M28.228 23.986L47.092 5.122a2.998 2.998 0 0 0 0-4.242 2.998
							2.998 0 0 0-4.242 0L23.986 19.744 5.121.88a2.998 2.998 0 0 0-4.242
							0 2.998 2.998 0 0 0 0 4.242l18.865 18.864L.879 42.85a2.998 2.998 0
							1 0 4.242 4.241l18.865-18.864L42.85 47.091c.586.586 1.354.879
							2.121.879s1.535-.293 2.121-.879a2.998 2.998 0 0 0 0-4.242L28.228
							23.986z" />
					</svg>
					<span class="sr-only">Close Modal</span>
				</button>
				<div class="modal-inner">
					<slot />
				</div>
			</div>
		</div>
	</div>
</div>
