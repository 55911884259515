import loadjs from 'loadjs'

export default function (el, locations) {
	if (!locations) {
		return
	}

	if (window.L) {
		makeMap(el, locations)
	} else {
		loadjs(
			'https://cdn.jsdelivr.net/npm/leaflet@1.5.1/dist/leaflet.js',
			makeMap.bind(null, el, locations)
		)
	}
}

function makeMap(el, locations) {
	const { L } = window

	// make map
	const map = L.map(el, {
		scrollWheelZoom: false,
	})
	map.zoomControl.setPosition('bottomright')
	L.tileLayer(
		'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYm9vc3RjcmVhdGl2ZWxsYyIsImEiOiJjbGI1YW1oZTUwNG90M29yemYxNW9nN2s3In0.PffNIBOLYqtnl4Ut4XebsA',
		{
			attribution:
				'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
			// tileSize: 512,
			maxZoom: 18,
			// zoomOffset: -1,
			id: 'mapbox/light-v10',
			accessToken:
				'pk.eyJ1IjoiYm9vc3RjcmVhdGl2ZWxsYyIsImEiOiJjbGI1YW1oZTUwNG90M29yemYxNW9nN2s3In0.PffNIBOLYqtnl4Ut4XebsA',
		}
	).addTo(map)

	// get coords from el attribute
	const markerCoords = locations.map((location) => {
		return location.coords ? location.coords.split(',') : false
	})

	// fit map to markers
	map.fitBounds(markerCoords)

	const icon = L.icon({
		iconUrl: `data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20259%20244%22%20transform%3D%22rotate(12)%22%3E%3ClinearGradient%20id%3D%22a%22%3E%3Cstop%20offset%3D%22.2%22%20stop-color%3D%22%23674d9d%22%2F%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23b185ba%22%2F%3E%3C%2FlinearGradient%3E%3Cpath%20fill%3D%22url(%23a)%22%20d%3D%22M129%201l38%2082%2091%2010-67%2061%2019%2088-80-44-79%2045%2018-88L1%2094l91-11%2037-82z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E`,
		iconSize: [30, 30],
		iconAnchor: [15, 30],
		popupAnchor: [0, -31],
	})

	locations.forEach((location) => {
		const { img, name, coords, url } = location
		if (!coords) {
			return
		}
		const coordArr = coords.split(',')
		let popupContent = `
      <div style="background-image:url(${img})"></div>
      <h4>${name}</h4>
      <a href="${url}" title="View"><span class="sr-only">View</span></a>
    `
		const marker = L.marker(coordArr, {
			icon,
		})
		const popup = L.popup().setContent(popupContent)
		marker.bindPopup(popup)
		marker.addTo(map)
	})
}
