<script>
	import loadjs from 'loadjs'

	export let serviceCats = []

	let activeCat = serviceCats[0]

	let grid

	function controlMasonry() {
		// on mount - init masonry
		let macyInstance
		loadjs('https://cdn.jsdelivr.net/npm/macy@2.5.0/dist/macy.min.js', () => {
			const { Macy } = window
			macyInstance = Macy({
				container: grid,
				columns: 2,
				margin: 30,
				breakAt: {
					800: {
						margin: 20,
						columns: 1,
					},
				},
			})
		})
		return {
			// on update - reinit masonry
			update() {
				macyInstance.reInit()
				window.updateScrollSections()
			},
		}
	}
</script>

{#if serviceCats.length > 1}
	<div class="service-selection">
		<h3 class="sr-only">Filter services</h3>
		{#each serviceCats as cat}
			<button
				class:active={cat === activeCat}
				title={cat.name}
				on:click={() => (activeCat = cat)}>
				{cat.name}
			</button>
			<div class="ss-divider" />
		{/each}
	</div>
{/if}
<div class="service-grid" bind:this={grid} use:controlMasonry={activeCat}>

	{#if activeCat.services}
		{#each activeCat.services as service (service)}
			<div class="service-item">
				{#if service.price}
					<div class="service-price">
						<p>
							Starting at
							<span>${service.price}</span>
						</p>
					</div>
				{/if}
				<div class="service-content">
					{#if service.name}
						<h3>{service.name}</h3>
					{/if}
					{#if service.desc}
						{@html service.desc}
					{/if}
				</div>
			</div>
		{/each}
	{/if}

</div>
