// import loadjs from 'loadjs'
import DragImages from '../components/drag-images.svelte'
import TeamGrid from '../components/team-grid.svelte'
import ServiceGrid from '../components/service-grid.svelte'
import { throttle } from 'throttle-debounce'

const { $ } = window

export default {
	init() {
		const $dragImages = $('#drag_images')
		const $teamGrid = $('#team_grid')
		const $servicesContainer = $('#services_container')

		if ($dragImages.length) {
			const images = $dragImages.data('images')
			images &&
				new DragImages({
					target: $dragImages[0],
					props: {
						images,
					},
				})
		}

		if ($teamGrid.length) {
			let members = $teamGrid.data('members')
			members &&
				new TeamGrid({
					target: $teamGrid[0],
					props: { members },
				})
		}

		// masonry - load macy
		if ($servicesContainer.length) {
			const serviceCats = $servicesContainer.data('services')
			serviceCats &&
				new ServiceGrid({
					target: $servicesContainer[0],
					props: { serviceCats },
				})
		}
	},
	finalize() {
		let sections = []
		let $lis = $('#page-top-nav li')

		window.updateScrollSections = function() {
			sections = []
			$('#page-top-nav a').each((i, el) => {
				const $section = $($(el).attr('href'))
				if ($section.length) {
					sections.push({
						el: $section,
						a: $(el),
						top: $section.offset().top - 90,
						bottom: $section.offset().top + $section.height() - 90,
					})
				}
			})
		}

		setTimeout(window.updateScrollSections, 250)

		$(window).on('scroll', throttle(99, setSection))
		$(window).on('resize', throttle(99, window.updateScrollSections))

		function setSection() {
			if (!sections.length) {
				return
			}
			let scrollAmount = (document.scrollingElement || document.documentElement)
				.scrollTop
			if (scrollAmount < sections[0].top) {
				$lis.removeClass('active')
				sections[0].a.parent().addClass('active')
			} else {
				for (let index = 0; index < sections.length; index++) {
					const section = sections[index]
					if (scrollAmount > section.top && scrollAmount < section.bottom) {
						$lis.removeClass('active')
						section.a.parent().addClass('active')
						break
					}
				}
			}
		}
	},
}
