import './app.scss'

import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
// import pageTemplateLocations from './routes/locations'
import singleLocation from './routes/location'

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	// pageTemplateLocations,
	singleLocation,
})

/** Load Events */
window.$(document).ready(() => routes.loadEvents())
