import Slideout from 'slideout'
import BigPicture from 'bigpicture'
import sal from 'sal.js'
import locationsMap from '../components/map'

const { $ } = window

export default {
	init() {
		// alert('hello')
		// JavaScript to be fired on all pages
		// init off canvas nav
		const slideout = new Slideout({
			panel: document.getElementById('panel'),
			menu: document.getElementById('menu'),
			padding: 270,
			tolerance: 200,
			side: 'right',
		})

		// toggle / close mobile menu
		$('#panel').on('click', function(e) {
			if (slideout._opened) {
				e.preventDefault()
				slideout.close()
			}
		})
		$('#toggle_nav').on('click', function(e) {
			e.preventDefault()
			if (!slideout._opened) {
				setTimeout(() => slideout.open(), 20)
			}
		})
		$(document).on(
			'click',
			'.menu-section .menu-item-has-children > a',
			function(e) {
				let $el = $(this)
				e.preventDefault()
				$el.parent().toggleClass('show-subnav')
			}
		)

		sal({
			threshold: 0.01,
			rootMargin: '-25px 9px',
		})
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		const $body = $(document.body)
		const $article = $('article.main-content')
		const $mapEl = $('#map')

		// class to hide outlines if not using keyboard
		$body.on('mousedown', function() {
			$body.addClass('using-mouse')
		})
		$body.on('keydown', function() {
			$body.removeClass('using-mouse')
		})

		// map stuff
		if ($mapEl.length) {
			const locations = $mapEl.data('locations')
			locationsMap($mapEl[0], locations)
		}

		// article enlarge images
		if ($article.length) {
			$article
				.find(
					'.wp-block-image a[href*=".jpg"], .wp-block-image a[href*=".jpeg"]'
				)
				.on('click', function(e) {
					const $this = $(this)
					const $imgEls = $this.find('img')
					e.preventDefault()
					BigPicture({
						el: $imgEls.length ? $imgEls[0] : this,
						imgSrc: this.href,
					})
				})
		}
	},
}
